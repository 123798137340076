import { Item } from 'types/MarketplaceAdsResponse';

export const getSavingsValue = (item: Item): number | undefined =>
  item.company_valuation ? (item.company_valuation - item.price) * -1 : undefined;

export const getEquipmentValues = (item: Item): string[] =>
  item.typeform.equipment.values.map((val) => val.value);

export const getCoverPhotoPath = (item: Item): string =>
  item.cover_photo?.children[0].full_path ?? '/assets/images/control-hero.jpg';
