import React from 'react';

import { Colors } from 'enums/Colors';
import { checkCircle } from 'icons/default';
import { Icon } from 'components/tokens/Icon';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { calculateMonthlyCost } from 'lib/loanCalculator/calculateMonthlyCostPrivateLoan';

import styles from './Footer.module.scss';

function getAge(date: string) {
  const now = new Date();
  const then = new Date(date);
  const diff = now.getTime() - then.getTime();
  const age = new Date(diff);
  return Math.abs(age.getUTCFullYear() - 1970); // TODO: Why 1970?
}

interface Props {
  price: number;
  first_time_in_traffic: string;
  monthly_cost: number;
  theme?: 'light' | 'dark';
  isCar: boolean;
  is_company_leasable: boolean;
}

export const Footer: React.FC<Props> = ({
  isCar,
  price,
  first_time_in_traffic,
  monthly_cost,
  theme = 'light',
  is_company_leasable,
}) => {
  let priceBody;
  if (monthly_cost) {
    priceBody = (
      <>
        <p className={styles.loanHeader}>Billån</p>
        <p className={`${styles.monthly} ${styles[theme]}`}>{`${getFormattedPrice(
          monthly_cost
        )}/mån`}</p>
        <p className={styles.price}>Pris: {getFormattedPrice(price)}</p>
      </>
    );
  } else if (false && price > 25000 && price < 400000) {
    priceBody = (
      <>
        <p className={styles.loanHeader}>Privatlån</p>
        <p className={`${styles.monthly} ${styles[theme]}`}>{`${getFormattedPrice(
          calculateMonthlyCost(price)
        )}/mån`}</p>
        <p className={styles.price}>Pris: {getFormattedPrice(price)}</p>
      </>
    );
  } else {
    priceBody = (
      <>
        <p className={styles.loanHeader}>Pris</p>
        <p className={`${styles.monthly} ${styles[theme]}`}>{`${getFormattedPrice(price)}`}</p>
      </>
    );
  }
  return (
    <div className={`${styles.footer} ${styles[theme]}`}>
      <div>{priceBody}</div>
      <div>
        {!first_time_in_traffic || getAge(first_time_in_traffic) < 20 || isCar ? (
          <p className={styles.checkHeading}>Kan fås med:</p>
        ) : null}
        {!first_time_in_traffic || getAge(first_time_in_traffic) < 20 ? (
          <div className={styles.check}>
            <Icon icon={checkCircle} size={12} color={Colors.MintSweet} />
            Garanti
          </div>
        ) : null}
        {isCar ? (
          <div className={styles.check}>
            <Icon icon={checkCircle} size={12} color={Colors.MintSweet} />
            Försäkring
          </div>
        ) : null}
        {isCar ? (
          <div className={styles.check}>
            <Icon icon={checkCircle} size={12} color={Colors.MintSweet} />
            Hemleverans
          </div>
        ) : null}
        {is_company_leasable ? (
          <div className={styles.check}>
            <Icon icon={checkCircle} size={12} color={Colors.MintSweet} />
            Företagsleasing
          </div>
        ) : null}
      </div>
    </div>
  );
};
