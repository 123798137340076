import React from 'react';

import { Icon, SVG } from 'components/tokens/Icon';
import { Colors } from 'enums/Colors';
import { questionCircle } from 'icons/default';

import styles from './Badge.module.scss';

interface Props {
  variant?: 'solid' | 'ghost';
  size?: 'tiny' | 'small' | 'medium' | 'large';
  icon?: SVG;
  tooltip?: string;
  color?: Colors;
  isInverted?: boolean;
  questionmarkIcon?: boolean;
}

export const Badge: React.FC<Props> = ({
  variant = 'solid',
  size = 'medium',
  icon,
  tooltip,
  color = Colors.DarkBlue,
  isInverted = false,
  children,
  questionmarkIcon = false,
}) => (
  <span
    className={`${styles.badge} ${styles[variant]} ${styles[size]} ${
      isInverted ? styles.inverted : ''
    } ${questionmarkIcon ? styles.questionmark : ''}`}
    style={{
      backgroundColor: `var(--color-${color})`,
      color: `var(--color-${isInverted ? Colors.White : Colors.DarkBlue})`,
    }}
  >
    {icon && <Icon icon={icon} size={14} color={isInverted ? Colors.White : Colors.DarkBlue} />}
    {children}
    {tooltip}
    {questionmarkIcon && (
      <Icon icon={questionCircle} size={12} color={isInverted ? Colors.White : Colors.DarkBlue} />
    )}
  </span>
);
