import React, { useRef, useState } from 'react';

import useEventListener from 'utils/useEventListener';
import useOnClickOutside from 'utils/useOnClickOutside';

import { CustomModal } from './CustomModal';
import styles from './Modal.module.scss';

interface Props {
  buttonContent: JSX.Element;
}

export const Modal: React.FC<Props> = ({ buttonContent, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useOnClickOutside(ref, handleClickOutside);
  useEventListener('keydown', handleEscape);

  return (
    <>
      <button
        type="button"
        className={styles.button}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {buttonContent}
      </button>

      {isOpen && (
        <CustomModal
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div className={styles['content-wrapper']} ref={ref}>
            {children}
          </div>
        </CustomModal>
      )}
    </>
  );
};
