import React from 'react';
import Link from 'next/link';

import { BackgroundImage } from 'components/BackgroundImage';
import { Badge } from 'components/Badge';
import { Colors } from 'enums/Colors';
import { legalScale, leaf } from 'icons/default';
import { getFormattedPrice } from 'utils/getFormattedPrice';
import { VehicleType } from 'enums/VehicleType';

import styles from './ProductCard.module.scss';
import { Footer } from './Footer';
import { Modal } from './Modal';

export enum ProductCardTheme {
  Light = 'light',
  Dark = 'dark',
}

export interface ProductCardProps {
  id: number;
  title: string;
  subtitle: string;
  location: string;
  km: number;
  fuel?: string;
  gearbox?: string;
  equipment: string[];
  price: number;
  first_time_in_traffic: string;
  monthly_cost: number;
  savings?: number;
  biddingInProgress?: boolean;
  imageSrc: string;
  theme?: ProductCardTheme;
  vehicleType: string;
  environmentally_friendly?: boolean;
  is_company_leasable: boolean;
  customCarTitle?: string;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  id,
  title,
  subtitle,
  location,
  km,
  fuel,
  gearbox,
  equipment,
  price,
  first_time_in_traffic,
  monthly_cost,
  savings,
  biddingInProgress = false,
  imageSrc,
  theme = ProductCardTheme.Light,
  vehicleType,
  environmentally_friendly,
  is_company_leasable,
  customCarTitle,
}) => {
  const savingsString = savings ? `${savings > 0 ? '+' : ''}${getFormattedPrice(savings)}` : '0';

  return (
    <div className={styles.wrapper}>
      <Link href={`/fordon/${id}`}>
        <a className={`${styles.card} ${styles[theme]}`}>
          <div className={styles.imageContainer}>
            <BackgroundImage src={imageSrc} alt={title} />
          </div>

          <div className={`${styles.content} ${styles[theme]}`}>
            <h3 className={styles.title}>{customCarTitle || title}</h3>
            <p className={styles.subtitle}>{subtitle}</p>
            <div className={`${styles.info} ${styles[theme]}`}>
              {location && <span>{location}</span>}
              {km && <span>{km.toLocaleString()} mil</span>}
              {fuel && <span>{fuel}</span>}
              {gearbox && <span>{gearbox}</span>}
            </div>
            <div className={styles.equipment}>
              {equipment.slice(0, 2).map((item) => (
                <Badge
                  size="tiny"
                  variant="ghost"
                  isInverted={theme === ProductCardTheme.Dark}
                  key={item}
                >
                  <span className={styles['equipment-ellipsis']}>{item}</span>
                </Badge>
              ))}
              {equipment.length > 2 && (
                <span className={`${styles['equipment-more']} ${styles[theme]}`}>
                  +{equipment.length - 2}
                </span>
              )}
            </div>
            <Footer
              price={price}
              first_time_in_traffic={first_time_in_traffic}
              monthly_cost={monthly_cost}
              theme={theme}
              isCar={vehicleType ? vehicleType.toLocaleLowerCase() === VehicleType.Car : false}
              is_company_leasable={is_company_leasable}
            />
          </div>
        </a>
      </Link>
      <div className={styles.badges}>
        {environmentally_friendly && (
          <Modal
            buttonContent={
              <Badge color={Colors.Yellowish} size="tiny" icon={leaf} questionmarkIcon>
                Bra miljöval
              </Badge>
            }
          >
            <h4>Bra miljöval</h4>
            <p>
              Bilar som släpper ut mindre än 70 gram CO<sub>2</sub>/km. Alternativt drivs med hjälp
              av el, kan tankas med gas eller är en laddhybrid.
            </p>
          </Modal>
        )}
        {savings && savingsString.includes('−') && (
          <Modal
            buttonContent={
              <Badge color={Colors.MintSweet} size="tiny" icon={legalScale} questionmarkIcon>
                {savingsString}
              </Badge>
            }
          >
            <h4>Jämförelsepris</h4>
            <p>
              Här visar vi en jämförelse mellan pris på blipp och vad motsvarande fordon kostar hos
              en bilhandlare.
            </p>
            <p>
              Värderingen görs av car.info och är baserad på ett estimerat försäljningspris som
              fordonet skulle haft hos en bilhandlare den dag det lades ut på blipp.se.
            </p>
          </Modal>
        )}
        {biddingInProgress && (
          <Modal
            buttonContent={
              <Badge color={Colors.SkyBlue300} size="tiny" questionmarkIcon>
                Budgivning pågår
              </Badge>
            }
          >
            <h4>Så fungerar budgivningen på Blipp</h4>
            <p>
              När bilen är publicerad börjar budgivningen. Alla som skickar bud är identifierade med
              BankID och enbart säljaren kan se buden.
            </p>
            <p>
              Budgivningen är inte tidsbegränsad utan det är helt upp till säljaren att tacka JA när
              man fått ett bud man tycker är tillräckligt bra, skicka ett motbud eller inte svara
              alls. Det betyder att alla fordon kan säljas precis när som helst.
            </p>
            <p>
              Vi har också ett filter som tar bort oseriöst låga bud. Tänk på att inga bud är
              bindande. När ett bud är accepterad är det dags att boka tid för visning.
            </p>
            <p>
              Vi jobbar aktivt mot oseriösa köpare och spärrar dessa i vårt system vid upprepade
              överträdelser.
            </p>
          </Modal>
        )}
      </div>
    </div>
  );
};
